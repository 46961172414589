<template>
    <a-drawer :title="title" :width="650" :visible="drawerVisible" :body-style="{ paddingBottom: '80px' }" @close="handleDrawer(false)">
        <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
            <!-- <a-form-item label="学科类别：" name="cate_id">
                <a-select ref="select" v-model:value="form.cate_id" placeholder="请选择学科类别" @change="filterList">
                    <a-select-option v-for="(item,idx) in seletArray.cate" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item> -->
            <a-form-item label="年级：" name="grade_id">
                <a-select ref="select" v-model:value="form.grade_id" placeholder="请选择年级" @change="filterList">
                    <a-select-option v-for="(item,idx) in seletArray.grade" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="学期：" name="term_id">
                <a-select ref="select" v-model:value="form.term_id" placeholder="请选择学期" @change="filterList">
                    <a-select-option v-for="(item,idx) in seletArray.term" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="科目：" name="subject_id">
                <a-select ref="select" v-model:value="form.subject_id" placeholder="请选择科目" @change="filterList">
                    <a-select-option v-for="(item,idx) in seletArray.subject" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="章节：" name="chapter_id">
                <a-select v-model:value="form.chapter_id" placeholder="请选择章节">
                    <a-select-option v-for="(item,idx) in chapter" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
            </a-form-item>
            <a-form-item label="知识点名称：" name="name">
                <a-input v-model:value="form.name" placeholder="请输入知识点名称" />
            </a-form-item>
        </a-form>
        <div class="submitBtn">
            <a-space>
                <a-button block @click="handleDrawer(false)">取消</a-button>
                <a-button block type="primary" @click="handleSubmit">保存</a-button>
            </a-space>
        </div>
    </a-drawer>
</template>
<script>
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, ref, toRefs, getCurrentInstance, onMounted, } from 'vue';

export default defineComponent({
    name: 'ConfigKnowledgeEdit',
    components: {
        LoadingOutlined,
        PlusOutlined,
    },
    props: {
        seletArray: {
            type: Object
        },
    },

    setup(props, ctx) {
        const { proxy } = getCurrentInstance();
        // 抽屉开关
        const drawerVisible = ref(false);
        const formRef = ref();

        onMounted(() => {
            ChapterList();
        });

        const initData = reactive({
            form: {},
            title: '添加知识点',
            chapter: [],
            rules: {
                name: [{
                    required: true,
                    message: '请输入知识点名称',
                }],
                // cate_id: [{
                //     required: true,
                //     message: '请选择学科类别',
                // }],
                grade_id: [{
                    required: true,
                    message: '请选择年级',
                }],
                term_id: [{
                    required: true,
                    message: '请选择学期',
                }],
                subject_id: [{
                    required: true,
                    message: '请选择科目',
                }],
                chapter_id: [{
                    required: true,
                    message: '请选择章节',
                }]
            }
        });
        const filterList = () => {
            initData.form.chapter_id=undefined;
            ChapterList();
        }
        const ChapterList = () => {
            //若类型、年级、学期、科目为空则禁止执行接口
            if (!(initData.form.grade_id && initData.form.subject_id && initData.form.term_id)) return;
            //获取章节数据
            proxy.http.post('/admin.chapter/indexAll', {
                cate_id: 1,
                grade_id: initData.form.grade_id,
                subject_id: initData.form.subject_id,
                term_id: initData.form.term_id,
            },false).then(response => {
                initData.chapter = response;
            })
        };

        // 保存
        const handleSubmit = () => {
            formRef.value.validate().then(() => {
                proxy.http.post('/admin.knowledge/save', initData.form).then((re) => {
                    ctx.emit('success')
                    proxy.message.success('提交成功');
                    handleDrawer(false)
                })
            }).catch(error => {});
        }

        //抽屉弹框状态
        const handleDrawer = (states) => {
            drawerVisible.value = states;
            if (!drawerVisible.value) formRef.value.resetFields();
        };

        return {
            ...toRefs(initData),
            filterList,
            drawerVisible,
            handleDrawer,
            handleSubmit,
            formRef,
            ChapterList
        };
    },
});
</script>

