<!--知识点管理-->
<template>
    <div>
        <a-row class="searchBox">
            <a-space class="flex flex-wrap">
                <!-- <a-select ref="select" v-model:value="param.cate_id" style="width: 150px" allowClear placeholder="学科类别" @change="param.chapter_id = undefined;handleSelectChange();ChapterList()">
                    <a-select-option v-for="(item,idx) in seletArray.cate" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select> -->
                <a-select ref="select" v-model:value="searchObject.grade_id" style="width: 150px" allowClear placeholder="年级" @change="searchFilter">
                    <a-select-option v-for="(item,idx) in seletArray.grade" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="searchObject.term_id" style="width: 150px" allowClear placeholder="学期"  @change="searchFilter">
                    <a-select-option v-for="(item,idx) in seletArray.term" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="searchObject.subject_id" style="width: 150px" allowClear placeholder="科目"  @change="searchFilter">
                    <a-select-option v-for="(item,idx) in seletArray.subject" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-select ref="select" v-model:value="searchObject.chapter_id" style="width: 200px" allowClear showSearch :filter-option="filterOption" placeholder="章节" @change="handleSelectChange">
                    <a-select-option v-for="(item,idx) in seletArray.chapter" :value="item.id" :key="item.id">{{item.name}}</a-select-option>
                </a-select>
                <a-button type="primary" @click="handleEdit()">
                    <PlusCircleOutlined />添加知识点
                </a-button>
            </a-space>
        </a-row>

        <a-row class="tableBox">
            <a-table :dataSource="list" :columns="columns" rowKey="id" bordered :pagination="false" :scroll="{x: 1000, y: 'calc(100vh - 390px)' }">
                <template #number="{text, record, index}">
                    <span>{{ (param.curr - 1) * param.row + index + 1 }}</span>
                </template>
                <template #video_count="{text, record, index}">
                    <div class="flex flex-ai-c flex-jc-sb" style="width:100%">
                        <span>{{text}}</span>
                        <icon-font type="icon-chakanliebiao" style="font-size:24px" @click="JumpVideo(record)" />
                    </div>
                </template>
                <template #operation="{ record }">
                    <a-space>
                        <a-button type="primary" @click="handleEdit(record)">编辑</a-button>
                        <a-button type="primary" @click="handleDelete(record)">删除</a-button>
                    </a-space>
                </template>
            </a-table>
            <div class="flex flex-jc-fe Pageing">
                <TablePagination :curr="param.curr" :row="param.row" :count="count" @changeTableRequest="changeTableRequest" />
            </div>
        </a-row>

        <!--添加知识点-->
        <ConfigKnowledgeEdit ref="ConfigKnowledgeEdit" :seletArray="seletArray" @success="getList"></ConfigKnowledgeEdit>
    </div>
</template>
<script>
import { PlusCircleOutlined } from '@ant-design/icons-vue';
import { defineComponent, reactive, toRefs, ref, getCurrentInstance, onMounted } from 'vue';
import TablePagination from '@/components/TablePagination';
import ConfigKnowledgeEdit from '@/components/ConfigKnowledgeEdit';
import useDelete from '@/hooks/useDelete.js';
import { useRoute } from 'vue-router'

export default defineComponent({
    components: {
        TablePagination,
        ConfigKnowledgeEdit,
        PlusCircleOutlined
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const route = useRoute();
        const { useDeleteFun } = useDelete();
        const ConfigKnowledgeEdit = ref();
        onMounted(() => {
            if (route.query.chapterId) {
                Object.assign(initData.searchObject, { grade_id: Number(route.query.gradeId), subject_id: Number(route.query.subjectId), term_id: Number(route.query.termId), chapter_id: Number(route.query.chapterId) });
            };
            TypeClass(); //类别、年级、科目、学期下拉数据
            getList();//知识点列表
            ChapterList();//章节列表
        });
        const initData = reactive({
            searchObject: {},
            seletArray: {},//下拉列表
            list: [],//知识点列表
            columns: [
                { title: '序号', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '知识点', dataIndex: 'name' },
                { title: '年级', dataIndex: 'grade.name' },
                { title: '学期', dataIndex: 'term.name' },
                { title: '科目', dataIndex: 'subject.name' },
                { title: '章节', dataIndex: 'chapter.name' },
                { title: '视频', dataIndex: 'video_count', slots: { customRender: 'video_count' } },
                { title: '录入时间', dataIndex: 'create_time' },
                { title: '操作', fixed: 'right', width: 180, slots: { customRender: 'operation' } },
            ],
            param: {
                curr: 1,
                row: 10
            },
            count: 0,
            pages: 1
        });

        const TypeClass = () => {
            proxy.http.post('/admin.chapter/config').then(response => {
                initData.seletArray = response;
            })
        };

        //获取章节
        const ChapterList = () => {
            initData.seletArray.chapter = [];
            if (!(initData.searchObject.grade_id && initData.searchObject.subject_id && initData.searchObject.term_id)) return;
            proxy.http.post('/admin.chapter/indexAll', initData.searchObject).then(response => {
                initData.seletArray['chapter'] = response;
            })
        }
        //获取知识点列表
        const getList = () => {
            Object.assign(initData.param, initData.searchObject);
            proxy.http.post('/admin.knowledge/index', initData.param).then(response => {
                initData.list = response.list;
                initData.count = response.count;
                initData.pages = response.pages;
            })
        };

        //分页change
        const changeTableRequest = (event, value) => {
            if (event == 'curr') {
                initData.param.curr = value;
            } else {
                initData.param.curr = 1;
                initData.param.row = value;
            }
            getList();
        };

        //下拉筛选过滤
        const filterOption = (input, option) => {
            return (
                option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        }

        const searchFilter = () => {
            initData.searchObject.chapter_id = undefined;
            handleSelectChange();
            ChapterList();
        };

        //下拉筛选
        const handleSelectChange = () => {
            initData.param.curr = 1;
            initData.list = [];
            getList();
        };

        //新建弹框
        const handleEdit = (row) => {
            let { chapter_id, grade_id, subject_id, name, term_id } = row ? row : initData.param;
            ConfigKnowledgeEdit.value.title = row ? '编辑知识点' : '新建知识点';
            ConfigKnowledgeEdit.value.form = row ? {
                id: row.id,
                cate_id: 1,
                chapter_id,
                grade_id,
                subject_id,
                name,
                term_id
            } : {
                cate_id: 1,
                chapter_id,
                grade_id,
                subject_id,
                term_id
            };
            ConfigKnowledgeEdit.value.ChapterList();
            ConfigKnowledgeEdit.value.handleDrawer(true);
        };

        //删除
        const handleDelete = (row) => {
            useDeleteFun('/admin.knowledge/delete', [row.id]).then(() => {
                getList();
            })
        }

        const JumpVideo = (row) => {
            proxy.http.go('/VideoSubject', {
                grade_id:row.grade_id,
                term_id:row.term_id,
                subject_id:row.subject_id,
                chapter_id:row.chapter_id,
                knowledge_id:row.id
            })
        }

        return {
            ...toRefs(initData),
            TypeClass,
            getList,
            searchFilter,
            ChapterList,
            ConfigKnowledgeEdit,
            changeTableRequest,
            handleEdit,
            handleDelete,
            handleSelectChange,
            filterOption,
            JumpVideo
        }
    }
})
</script>
